import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { space, media } from '../styles/constants'

import { MainWrapper } from '../components/Wrapper'
import { Container, SiteGrid } from '../components/Container'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Button } from '../components/Button'
import Layout from '../components/Layout'
import { BodyText, SectionHeader } from '../components/Type'

const NotFoundContainer = styled(Container)`
`

const NotFoundContent = styled.div`
    text-align: center;
    margin: ${space.p100} 0;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 2;
        grid-column-end: 8;
    `}
    ${media.xl`
        grid-column-start: 4;
        grid-column-end: 10;
    `}
`

const NotFoundText = styled(BodyText)`
    margin: ${space.p200} 0;
`

const NotFoundCta = styled.div`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 3;
        grid-column-end: 7;
    `}
    ${media.xl`
        grid-column-start: 4;
        grid-column-end: 10;
    `}
`

const NotFound = () => {
    const siteData = useStaticQuery(graphql`
        query{
            site{
                siteMetadata{
                    indexTitle
                }
            }
        }
    `)

    return(
        <Layout title={siteData.site.siteMetadata.indexTitle}>
            <MainWrapper />
            <SiteGrid>
                <Header />
                    <NotFoundContainer>
                        <SectionHeader>Whoa there!</SectionHeader>
                        <NotFoundContent>
                            <NotFoundText>
                                Are you lost, traveller?
                            </NotFoundText>
                            <NotFoundText>
                                No Narnia here; one day <em>perhaps</em>.
                            </NotFoundText>
                            <NotFoundText>
                                In the meantime, let's take you back home.  
                            </NotFoundText>
                        </NotFoundContent>
                    <NotFoundCta>
                        <Link to="/">
                            <Button>Take me home</Button>
                        </Link>
                    </NotFoundCta>
                </NotFoundContainer>
                <Footer />
            </SiteGrid>
        </Layout>
    )
}

export default NotFound