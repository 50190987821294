// Refactor buttons to not carry any width attribute.  
// They should follow the width of the parent container

import styled, { keyframes } from 'styled-components'
import { color, colorRgb, media, space, fontSize } from '../../styles/constants'

const Breathe = keyframes`
    0% {
        background-size: 300% 400%;
    }
    25% {
        background-size: 600% 800%;
        box-shadow: 0px 0px 2px 1px rgba(${colorRgb.acBgRed}, 0.5);
    }
    50% {
        box-shadow: 0px 0px 1px 1px rgba(${colorRgb.acBgYellow}, 0.7);
    }
    75%{
        background-size: 240% 320%;
    }
    100% {
        background-size: 300% 400%;
        box-shadow: none;
    }
`

export const Button = styled.button.attrs(props => ({
    facontent: props.facontent || "",
    faweight: props.faweight || "900"
}))`
    
    display: inline-block;
    position: relative;
    width: 100%;
    cursor: pointer;
    padding: ${space.p75};
    border-radius: 100rem;
    font-size: ${fontSize.f125};
    color: ${color.blue500};
    border: none;
    background-position: center top;
    background-repeat: no-repeat;
    background-image: radial-gradient(at 20% 80%, ${color.acGreen} 0, transparent 60%),  
        radial-gradient(at 60% 80%, ${color.blue200} 0, transparent 60%),  
        radial-gradient(at 80% 10%, ${color.blue300} 0, transparent 25%),
        linear-gradient(90deg, ${color.acBgBlue}, ${color.acBgBlue});
    transition: background-position ease-in-out 1s, transform ease-in-out 0.4s;
    background-size: 300% 400%;

    &:hover{
        background-position: left bottom;
        transform: translateY(-4px);
    }
    // For the fontawesome icon if it exists
    &:after{
        content: "${props => props.facontent}";
        font-family: "Font Awesome 5 Free";
        font-weight: ${props => props.faweight};
        position: absolute;
        padding-left: ${space.p100};
        vertical-align: baseline;
        opacity: 0;
        transition: all ease-out 0.4s;
    }

    &:hover:after{
        opacity: 0.7;
        padding-left: ${space.p75};
    }

    &:hover:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${color.acBgRed};
        opacity: 0.1;
        z-index: -240;
        width: 100%;
        height: 100%;
        border-radius: 100rem;
        transition: all 0.2s ease-in-out;
    }

    &:active:before{
        opacity: 0;
        z-index: 48;
        transform-origin: center;
        transform: scaleX(1.4) scaleY(1.6);
    }
`

export const PrimaryButton = styled(Button)`
    padding: ${props => (props.amplify ? space.p100 : space.p75)};
    font-size: ${props => (props.amplify ? fontSize.f150 : fontSize.f125)};
    animation: ${Breathe} 8s ease-in-out infinite;
    background-image:
        radial-gradient(at 42% 77%, ${color.acYellow} 0, transparent 70%),  
        radial-gradient(at 78% 66%, ${color.acGreen} 0, transparent 52%),  
        radial-gradient(at 24% 22%, ${color.acBlue} 0, transparent 25%),
        linear-gradient(-135deg, rgba(${colorRgb.acRed}, 1), rgba(${colorRgb.acRed}, 1));
`

// Contemplate removal and use a container to alter widths
export const PrimaryButtonGeneral = styled(PrimaryButton)`
    grid-column-start: 1;
    grid-column-end: 4;
`

// Contemplate removal and use a container to alter widths
export const ButtonGeneral = styled(Button)`
    grid-column-start: 1;
    grid-column-end: 3;
`

export const ButtonIcon = styled.span`
    padding: 0 0 0 ${space.p75};
    opacity: 0.7;
    font-size: ${fontSize.f100};
    vertical-align: middle;
    // How annoying
    padding-bottom: ${space.p25};
`

/*

background-image:
        radial-gradient(at 16% 63%, hsla(164,77%,62%,1) 0, transparent 52%),  
        radial-gradient(at 95% 74%, hsla(203,100%,60%,1) 0, transparent 41%),  
        radial-gradient(at 29% 33%, hsla(48,99%,70%,1) 0, transparent 70%),
        linear-gradient(-135deg, rgba(${colorRgb.acRed}, 1), rgba(${colorRgb.acRed}, 1));


*/

// Need primary cta (regular and amplified size), and secondary cta

/*

export const CtaButton = styled(Button)`
    background: linear-gradient(90deg, rgba(${colorRgb.white}, 1) 0%, rgba(${colorRgb.white}, 0.6) 100%);
    color:${color.blue300};
    border: none;

    :hover{
        background: linear-gradient(90deg, rgba(${colorRgb.blue400}, 1) 0%, rgba(${colorRgb.blue100}, 1) 100%);
        color:${color.white};
        box-shadow:  0rem 0.5rem 1rem rgba(${colorRgb.black}, 0.5),
                    0rem -0.25rem 0.5rem rgba(${colorRgb.white}, 0.1);
    }
    :active{
        box-shadow:  inset 0rem 0.5rem 1rem rgba(${colorRgb.black}, 0.5),
                    inset 0rem -0.25rem 0.5rem rgba(${colorRgb.white}, 0.1);
    }

    ${media.xs`
        margin: ${space.p50} 0;
    `}
    ${media.lg`
        margin: 0;
    `}
`

export const PrimaryCtaButton = styled(CtaButton)`
    background: linear-gradient(90deg, rgba(${colorRgb.acBgGreen}, 1) 0%, rgba(${colorRgb.acGreen}, 0.6) 100%);
    color:${color.blue400};

    :hover{
        background: linear-gradient(90deg, rgba(${colorRgb.acBgRed}, 0.8) 0%, rgba(${colorRgb.acYellow}, 0.5) 100%);
        color: ${color.black};
    }
`

*/

/*
Button
background-image: 
        radial-gradient(at 39% 63%, ${color.acBgGreen} 0, transparent 47%),  
        radial-gradient(at 63% 45%, ${color.blue100} 0, transparent 42%),  
        radial-gradient(at 36% 33%, ${color.acBlue} 0, transparent 43%),
        linear-gradient(-135deg, rgba(${colorRgb.blue100}, 1), rgba(${colorRgb.blue100}, 0.8));

Primary button
background-image:
        radial-gradient(at 42% 77%, ${color.acYellow} 0, transparent 70%),  
        radial-gradient(at 78% 66%, ${color.acGreen} 0, transparent 52%),  
        radial-gradient(at 24% 22%, ${color.acBlue} 0, transparent 25%),
        linear-gradient(-135deg, rgba(${colorRgb.acRed}, 1), rgba(${colorRgb.acRed}, 1));

*/